
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































.showroom-card {
  position: relative;
}

.showroom-card__picture {
  @extend %border-radius;

  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 3/2;
  transition: opacity 0.3s $ease-out-cubic;

  .picture {
    @include image-fit;

    transition: transform 0.3s $ease-out-cubic;
  }

  &:hover,
  &:focus {
    .picture {
      transform: scale(1.1);
    }
  }

  .showroom-card.is-loading & {
    opacity: 0.7;
  }
}

.showroom-card__picture__loader {
  @include center-xy;

  display: none;

  .showroom-card.is-loading & {
    display: block;
  }
}

.showroom-card__label {
  display: flex;
  align-items: center;
}

.showroom-card__event {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: calc(100% - #{$spacing * 1.2});
  margin: $spacing * 0.6;
  padding: $spacing * 0.25;
  background: $c-white;
  border-radius: 2rem;
  box-shadow: $card-shadow;
  pointer-events: none;
}

.showroom-card__event__icon {
  display: flex;
  flex-shrink: 0;
  padding: $spacing * 0.25;
  color: $c-white;
  background: $c-mustard;
  border-radius: 50%;

  .icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.showroom-card__event__content {
  flex: 1;
  margin: 0 $spacing * 0.5;
  font-size: 1.3rem;
  font-style: italic;
  line-height: 1;

  strong {
    display: block;
    color: $c-mustard;
    font-style: normal;
  }
}
