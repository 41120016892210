
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































































































































.showrooms__heading {
  position: relative;
  display: flex;
  flex-direction: column;

  @include mq(l) {
    display: block;
  }
}

.showrooms__heading__crumbs {
  order: -2;
}

.showroom__heading__pill {
  order: -1;
  margin: 0 auto $spacing * 1.6;

  @include mq(l) {
    @include center-y;

    right: col(1, 24);
    max-width: 30%;
    margin: 0;

    &[href]:hover,
    &[href]:focus-visible {
      transform: translateY(-50%) scale(1.02);
    }
  }

  @include mq(xl) {
    right: col(2, 24);
    max-width: none;
  }
}

.showrooms__content__showrooms {
  @include default-margin(0.8);
}

.showrooms__content__showrooms__title {
  margin-top: $spacing;
}

.showrooms__content__showrooms__list {
  @extend %list-nostyle;
  @include default-margin(0.2);

  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
  }
}

.showrooms__content__showrooms__item {
  margin-top: $spacing * 1.5;

  @include mq(m) {
    flex-basis: (math.div(97%, 2));

    &:nth-child(2n) {
      margin-left: 3%;
    }
  }

  @include mq(l) {
    flex-basis: (math.div(96%, 3));
    margin-top: $spacing * 2;

    & + & {
      margin-left: 2%;
    }

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
}
